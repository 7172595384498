

































































































import { defineComponent, reactive, ref, watch } from '@vue/composition-api';
import api from '@/services';
import useRouteQuery from '@/utils/uses/routerQuery/useRouteQuery';
import { useState } from 'vuex-composition-helpers/dist';

import { fileClipboardObj } from '@/InterfaceModel/Files';
import { mapState } from 'vuex';
//  import { VueSignaturePad } from "vue-signature-pad";
export default defineComponent({
	// components: { VueSignaturePad },
	setup: props => {
		const { auth } = useState(['auth']);
		const { queryRoute, stringQueryRender, getQueryRoute } = useRouteQuery();
		const currentRouteQuery = ref<string>(stringQueryRender);
		const currentTab = ref<string>('drawSign');
		let fileClipboard = ref<fileClipboardObj>();
		const authVal = auth.value;
		const user = authVal.user ? authVal.user.user : null;
		const messageErr = ref<string>('');
		const loadingSave = ref<boolean>(false);
		const acceptedFiles = ['png', 'jpg', 'webp', 'jpeg', 'svg'];
		const limitFiles = {
			amount: 1,
			maxFileSize: 104857600, // 100MB,
		};
		const setFileClipboard = (payload: fileClipboardObj) => {
			fileClipboard.value = payload;
		};
		const clearFileClipboard = () => {
			fileClipboard.value = { _id: 0, type: 'none', blob: 'none' };
		};
		const setCurrentTab = (tab: string) => {
			currentTab.value = tab;
		};
		const setMessageErr = (str: string) => {
			messageErr.value = str;
		};
		const setLoadingSave = (flag: boolean) => {
			loadingSave.value = flag;
		};
		return {
			queryRoute,
			auth,
			user,
			currentRouteQuery,
			fileClipboard,
			currentTab,
			loadingSave,
			acceptedFiles,
			limitFiles,
			setFileClipboard,
			clearFileClipboard,
			setCurrentTab,
			messageErr,
			setMessageErr,
			setLoadingSave,
		};
	},
	computed: {
		...mapState({
			currentSignature: (state: any) => state.signature.currentSignature,
		}),
	},
	watch: {},
	mounted() {
		//  this.$store.commit("SET_SHOWFILTER", false);
		this.$store.commit('SET_ADDLIB', false);
		this.$store.commit('SET_ADDLIBACTION', () => {
			console.log('nth');
		});
	},
	methods: {
		undo() {
			if (!this.$refs.signaturePad) {
				return;
			}
			const ele: any = this.$refs.signaturePad;
			ele.undoSignature();
		},
		save() {
			if (!this.$refs.signaturePad) {
				return;
			}
			const ele: any = this.$refs.signaturePad;
			const { isEmpty, data } = ele.saveSignature();
			if (!isEmpty) {
				this.onSubmitSign({
					signature: data,
				});
			}
		},
		clear() {
			if (!this.$refs.signaturePad) {
				return;
			}
			const ele: any = this.$refs.signaturePad;
			ele.clearSignature();
		},
		onSaveFile() {
			if (this.currentTab === 'drawSign') {
				this.save();
			} else {
				const formData = new FormData();
				if (this.fileClipboard && this.fileClipboard.blob) {
					formData.append('signature', this.fileClipboard.blob);
					this.onSubmitSign(formData);
				}
			}
		},
		async onSubmitSign(payload: Record<string, any>) {
			this.setLoadingSave(true);
			const res = await api.signature.update(payload);
			this.setLoadingSave(false);
			if (!res) {
				this.$store.commit('SET_SNACKBAR', {
					type: 'error',
					title: '',
					content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
				});
				return;
			}
			try {
				if (res.status > 199 && res.status < 399) {
					this.$store.commit('SET_SNACKBAR', {
						type: 'success',
						title: '',
						content: 'Tạo mới chữ ký thành công.',
					});
					this.$store.dispatch('signature/getCurrentSignature');
				} else {
					this.setMessageErr(res.data.message || res.data.data.error);
					this.$store.commit('SET_SNACKBAR', {
						type: 'error',
						title: '',
						content: res.data.message || res.data.data.error,
					});
				}
			} catch (error) {
				console.log(error);
				this.$store.commit('SET_SNACKBAR', {
					type: 'error',
					title: '',
					content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
				});
			}
		},
		onFileChange(e: any) {
			const files = e.target.files || e.dataTransfer.files;
			if (!files.length) return;
			const file = files[0];
			const reader = new FileReader();
			reader.onload = async (event: any) => {
				const img = document.createElement('img');
				img.src = event.target.result;
				this.setFileClipboard({
					_id: `${new Date().getTime()}_${Math.random()}`,
					name: file.name,
					src: event.target.result,
					type: 'image',
					blob: file,
					sizes: file.size,
				});
			};
			reader.readAsDataURL(file);
		},
		onClear() {
			if (this.currentTab === 'drawSign') {
				this.clear();
			} else {
				this.clearFileClipboard();
			}
		},
		onSave() {
			if (this.currentTab === 'drawSign') {
				this.save();
			} else {
				this.onSaveFile();
			}
		},
		addImages(_url = '') {
			if (!this.$refs.signaturePad) {
				return;
			}
			const ele: any = this.$refs.signaturePad;
			const imgArr = [
				{
					src: _url,
					x: 0,
					y: 0,
				},
			];
			ele.addImages(imgArr);
		},
		dragover(e: any) {
			if (!this.ableDrag) {
				e.preventDefault();
				return;
			}
			console.log('dragover');
		},
		dragend(e: any) {
			if (!this.ableDrag) {
				e.preventDefault();
				return;
			}
			console.log('dragend');
		},
		dragstart(e: any) {
			if (!this.ableDrag) {
				e.preventDefault();
				return;
			}
			console.log('dragover');
			// console.log(e.clientY);
		},
		dropHandler(ev: any) {
			// const _this = this;
			console.log('dragged');
			// Prevent default behavior (Prevent file from being opened)
			ev.preventDefault();
			console.log(ev.dataTransfer.items);
			if (ev.dataTransfer.items) {
				// Use DataTransferItemList interface to access the file(s)
				if (ev.dataTransfer.items.length > this.limitFiles.amount) {
					alert('Chỉ có thể kéo thả 1 file/lần !');
				} else {
					let listFileBlob = [];
					for (let i = 0; i < ev.dataTransfer.items.length; i++) {
						let file = ev.dataTransfer.items[i].getAsFile();
						// console.log(file, 'file test');
						if (!file) {
							return;
						}
						if (!file.type.includes('image')) {
							listFileBlob.push({
								blob: file,
								name: file.name,
								type: file.type,
								size: file.size,
							});
						}
					}
					if (
						listFileBlob.filter(o => {
							return o.size == 0;
						}).length
					) {
						alert('File không hợp lệ !');
						return;
					}
					for (let i = 0; i < ev.dataTransfer.items.length; i++) {
						// If dropped items aren't files, reject them
						let entry = ev.dataTransfer.items[i].webkitGetAsEntry();
						if (entry.isDirectory) {
							alert('File không hợp lệ !');
							return;
						}
						if (ev.dataTransfer.items[i].kind === 'file') {
							let file = ev.dataTransfer.items[i].getAsFile();
							// console.log(file);
							file.namedDot = String(String(file.name).split('.').pop()).toLowerCase();
							if (!this.acceptedFiles.includes(file.namedDot)) {
								alert('File không hợp lệ !');
								return;
							}
							if (file.size > this.limitFiles.maxFileSize) {
								alert('File quá lớn !');
							} else {
								if (this.acceptedFiles.includes(file.namedDot)) {
									if (file.type.includes('image')) {
										// console.log('img drag c2');
										const reader = new FileReader();
										reader.onload = async (ev: any) => {
											const img = document.createElement('img');
											img.src = ev.target.result;
											this.setFileClipboard({
												_id: `${new Date().getTime()}_${Math.random()}`,
												name: file.name,
												src: ev.target.result,
												type: 'image',
												blob: file,
												sizes: file.size,
											});
										};
										reader.readAsDataURL(file);
									}
								}
							}
						}
					}
				}
			} else {
				// Use DataTransfer interface to access the file(s)
				for (let i = 0; i < ev.dataTransfer.files.length; i++) {
					// console.log(
					//   '... file[' + i + '].name = ' + ev.dataTransfer.files[i].name
					// );
				}
			}
		},
	},
});
