const approvalRepo = (axios: any) => {
	return {
		getStatistical(filterOptions: Record<string, unknown> = {}) {
			const body = {
				...filterOptions,
				// include: filterOptions.include ? `${filterOptions.include},parts,parts.warehouse,parts.warehouse_off_airport_terminal` : "parts",
			};
			return axios
				.get(`api/approval-requests/statistics`, {
					params: body,
				})
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		getAll(filterOptions: Record<string, unknown> = {}) {
			const body = {
				...filterOptions,
				// include: filterOptions.include ? `${filterOptions.include},parts,parts.warehouse,parts.warehouse_off_airport_terminal` : "parts",
			};
			return axios
				.get(`api/approval-requests`, {
					params: body,
				})
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		getDetail(_id: number) {
			return axios
				.get(`api/approval-requests/${_id}?include=fields,approvers,followers,category,creator,templates.sign_at`)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		getListLogs(_id: number | string, _params: any = {}) {
			return axios
				.get(`/api/approval-requests/${_id}/logs?include=causer`, {
					params: _params,
				})
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		getListTodoAvailable() {
			return axios
				.get(`/api/staff/tasks/associable`, {
					params: {
						pagination: false,
					},
				})
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		getListParentAvailable(id: string | number, _params = {}) {
			return axios
				.get(`/api/approval-requests/${id}/parentable`, {
					params: _params,
				})
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		getListChildAvailable(id: string | number, _params = {}) {
			return axios
				.get(`/api/approval-requests/${id}/childable`, {
					params: _params,
				})
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},

		detachTodo(_id: number | string, _params: any = {}) {
			return axios
				.post(`/api/approval-requests/${_id}/tasks/detach`, _params)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		detachChild(_id: number | string, _params: any = {}) {
			return axios
				.post(`/api/approval-requests/${_id}/remove-child`, _params)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		detachParent(_id: number | string, _params: any = {}) {
			return axios
				.post(`/api/approval-requests/${_id}/remove-parent`, _params)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		addChildRelate(_id: number | string, _params: any = {}) {
			return axios
				.post(`/api/approval-requests/${_id}/add-children`, _params)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		addParentRelate(_id: number | string, _params: any = {}) {
			return axios
				.post(`/api/approval-requests/${_id}/add-parents`, _params)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		attachTodo(_id: number | string, _params: any = {}) {
			return axios
				.post(`/api/approval-requests/${_id}/tasks/attach`, _params)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		addFollower(_id: number | string, _params: any = {}) {
			return axios
				.post(`/api/approval-requests/${_id}/attach-followers`, _params)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		addUser(approval_id: number | string, title_id: number | string, _params: any = {}) {
			return axios
				.put(`/api/approval-requests/${approval_id}/approver-titles/${title_id}`, _params)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		changeApproval(approval_id: number | string, _params: any = {}) {
			return axios
				.post(`/api/approval-requests/${approval_id}/authorize`, _params)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		unapproval(approval_id: number | string) {
			return axios
				.post(`/api/approval-requests/${approval_id}/unapprove`)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		turnOnNoti(approval_id: number | string) {
			return axios
				.post(`/api/approval-requests/${approval_id}/turn-on-notification`)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		turnOffNoti(approval_id: number | string) {
			return axios
				.post(`/api/approval-requests/${approval_id}/turn-off-notification`)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		addTask(approval_id: number | string | string, _params: any = {}) {
			return axios
				.post(`/api/approval-requests/${approval_id}/tasks`, _params)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		addFile(_id: number | string, _params: any = {}) {
			return axios
				.post(`/api/approval-requests/${_id}/add-attachments`, _params)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		rmFile(_id: number | string, _params: any = {}) {
			return axios
				.post(`/api/approval-requests/${_id}/remove-attachment`, _params)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		removeFollower(_id: number | string, _params: any = {}) {
			return axios
				.post(`/api/approval-requests/${_id}/detach-followers`, _params)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		getListComment(_id: number | string, _params: any = {}) {
			return axios
				.get(`/api/approval-requests/${_id}/comments`, { params: _params })
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		comment(_id: number | string, _params: any = {}) {
			return axios
				.post(`/api/approval-requests/${_id}/comments`, _params)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		updateComment(approvalId: number | string, commentId: number | string, _params: any = {}) {
			return axios
				.post(`api/approval-requests/${approvalId}/comments/${commentId}`, _params)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		deleteComment(approvalId: number | string, commentId: number | string) {
			return axios
				.delete(`api/approval-requests/${approvalId}/comments/${commentId}`)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},

		approve(_id: string | number) {
			return axios
				.post(`/api/approval-requests/${_id}/approve`)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		decline(_id: string | number, filterOptions: Record<string, unknown> = {}) {
			const body = {
				...filterOptions,
			};
			return axios
				.post(`/api/approval-requests/${_id}/reject`, body)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
	};
};
export default approvalRepo;
