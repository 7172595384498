const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;
import Vue from 'vue';
import Vuex from 'vuex';

import axios from 'axios';

import others from './others';
import users from './users';
import departments from './departments';
import signature from './signature';
import tasks from './tasks';
import categories from './categories';
import fileType from './fileType';
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		auth: {
			isAuth: false,
			user: null,
			token: null,
		},
		currentBreadcrumb: [
			{
				text: 'Dashboard',
				disabled: false,
				href: 'breadcrumbs_dashboard',
			},
			{
				text: 'Link 1',
				disabled: false,
				href: 'breadcrumbs_link_1',
			},
			{
				text: 'Link 2',
				disabled: true,
				href: 'breadcrumbs_link_2',
			},
		],
		snackbar: {
			type: '', // success, error, warning
			title: '',
			content: '',
		},
		previousPagination: {
			total: 1,
			size: 15,
			total_pages: 15,
			page: 1,
		},
		logoutIsOpen: false,
		isMini: false,
		visibleDialog: false,
		breadCrumb: {
			viewTxt: 'ASGL',
			ableToBack: false,
			rootRouter: '/',
			hasStatus: false,
			statusTxt: 'Status',
		},
		approvalRequestStatus: [
			{
				id: 1,
				name: 'Nháp',
			},
			{
				id: 2,
				name: 'Đã gửi',
			},
			{
				id: 3,
				name: 'Đã phê duyệt',
			},
			{
				id: 4,
				name: 'Không phê duyệt',
			},
			{
				id: 5,
				name: 'Hủy',
			},
		],
		listProposeStatus: [
			{
				id: 0,
				name: 'Tất cả',
			},
			{
				id: 1,
				name: 'Chờ duyệt',
			},
			{
				id: 2,
				name: 'Đã duyệt',
			},
			{
				id: 3,
				name: 'Đã từ chối',
			},
			{
				id: 4,
				name: 'Hoàn thành',
			},
		],
		listImgPreview: [],
		currentNotiCounter: 30,
		preventLoadmoreNoti: false,
		onLoadingNoti: false,
		totalNotiCounter: {
			total: 1,
			total_unread: 1,
			total_unreceived: 1,
		},
		imageIdx: 0,
		showFilter: true,
		addLib: true,
		placeHolder: 'Tìm kiếm đề xuất',
		addLibAction: () => {
			console.log('Emit fnc');
		},
		statistical: {},
		currentCK4: null,
		listNoti: [],
	},
	mutations: {
		SET_SNACKBAR(state, payload) {
			state.snackbar = payload;
			setTimeout(() => {
				state.snackbar = {
					type: '',
					title: '',
					content: '',
				};
			}, 6000);
		},
		CACHED_PAGINATION(state, payload) {
			state.previousPagination = payload;
		},
		UPDATE_READ_NOTI(state: Record<string, any>, noti: any) {
			state.listNoti.forEach((o: Record<string, any>) => {
				if (o.id === noti.id) {
					o.read_at = true;
				}
			});
		},
		SET_LIST_NOTI(state, payload) {
			state.listNoti = payload;
		},
		SET_LOADING_NOTI(state, payload) {
			state.onLoadingNoti = payload;
		},
		SET_CURRENT_NOTI_COUNTER(state, payload) {
			state.currentNotiCounter = payload;
		},
		SET_PREVENT_LOAD_NOTI(state, payload) {
			state.preventLoadmoreNoti = payload;
		},
		SET_NOTI_TOTAL(state, payload) {
			state.totalNotiCounter = payload;
		},
		SET_LIST_IMAGE_PREVIEW(state, payload) {
			state.listImgPreview = payload;
		},
		SET_CURRENT_CK4(state, payload) {
			state.currentCK4 = payload;
		},
		SET_INDEX_IMAGE_PREVIEW(state, payload) {
			state.imageIdx = payload;
		},
		SET_USER_LOGGEDIN(state, payload) {
			state.auth = { ...payload };
		},
		SET_LOGOUT_USER(state) {
			state.auth = { isAuth: false, user: null, token: null };
		},
		CONFIRM_LOGOUT(state, payload) {
			state.logoutIsOpen = payload;
		},
		SET_MINI(state, payload) {
			state.isMini = payload;
		},
		SET_VISIBLE_DIALOG(state, payload) {
			state.visibleDialog = payload;
		},
		SET_BREADCRUMB(state, payload) {
			state.breadCrumb = payload;
		},
		SET_SHOWFILTER(state, _boolean) {
			state.showFilter = _boolean;
		},
		SET_ADDLIB(state, _boolean) {
			state.addLib = _boolean;
		},
		SET_PLACEHOLDER(state, _STRING) {
			state.placeHolder = _STRING;
		},
		SET_ADDLIBACTION(state, _FUNCTION) {
			state.addLibAction = _FUNCTION;
		},
		SET_STATISTICAL(state, payload) {
			state.statistical = payload;
		},
	},
	actions: {
		getStatistical({ commit }: any) {
			axios
				.get(`${VUE_APP_BASE_URL}/api/approval-requests/statistics`)
				.then(res => {
					commit('SET_STATISTICAL', res.data.data);
				})
				.catch(err => {
					console.log(err);
				});
		},
		getListNoti({ commit, state }: any, params: Record<string, any>) {
			commit('SET_PREVENT_LOAD_NOTI', true);
			commit('SET_LOADING_NOTI', true);
			axios
				.get(`${VUE_APP_BASE_URL}/api/notifications`, {
					params: {
						size: state.currentNotiCounter,
						...params,
					},
				})
				.then(
					res => {
						commit('SET_PREVENT_LOAD_NOTI', false);
						commit('SET_LOADING_NOTI', false);
						commit('SET_LIST_NOTI', res.data.data.notifications);
						commit('SET_NOTI_TOTAL', {
							total: res.data.data.meta.pagination.total,
							total_unread: res.data.data.meta.total_unread,
							total_unreceived: res.data.data.meta.total_unreceived,
						});
					},
					err => {
						commit('SET_PREVENT_LOAD_NOTI', false);
						commit('SET_LOADING_NOTI', false);
					}
				)
				.catch(err => {
					commit('SET_PREVENT_LOAD_NOTI', false);
					commit('SET_LOADING_NOTI', false);
					console.log(err);
				});
		},
	},
	getters: {
		getUser(state) {
			return state.auth.user;
		},
	},
	modules: {
		others,
		users,
		departments,
		signature,
		tasks,
		categories,
		fileType,
	},
});
