





























import Vue from 'vue';
import { mapState } from 'vuex';
import PreviewImage from '@/components/Layout/PreviewImage.vue';

export default Vue.extend({
	name: 'App',
	components: {
		PreviewImage,
	},
	// beforeMount(){}
	data: () => ({
		snackbarEnable: true,
		firstloadingp: true,
		refInterval: 0,
	}),
	computed: {
		...mapState({
			snackbarState: (state: any) => state.snackbar,
			listImgPreview: (state: any) => state.listImgPreview,
			itemIdx: (state: any) => state.imageIdx,
			auth: (state: any) => state.auth,
		}),
		onViewDetailImg() {
			return !!this.listImgPreview && this.listImgPreview.length;
		},
		snackBarClasViaType() {
			if (!this.snackbarState || !this.snackbarState.type) {
				return '';
			}
			return `snackbar-on-${this.snackbarState.type}`;
		},
	},
	mounted() {
		// this.firstloadingp = false;
		if (this.auth && this.auth.isAuth) {
			this.$store.dispatch('signature/getCurrentSignature');
			this.$store.dispatch('categories/getListCategories');
			this.$store.dispatch('users/getDepartments');
			this.$store.dispatch('users/getCompanies');
			this.$store.dispatch('users/getUsers');
			this.$store.dispatch('users/getUserActive');
			this.$store.dispatch('others/getAllFields');
			this.$store.dispatch('getListNoti');
			if (this.refInterval) {
				clearInterval(this.refInterval);
			}
			// this.refInterval = setInterval(() => {
			// 	this.$store.dispatch('getListNoti');
			// }, 12000);
		}
		if (window) {
			window.addEventListener('online', () => {
				this.$store.commit('SET_SNACKBAR', {
					type: 'success',
					title: '',
					content: 'Bạn đã kết nối trở lại',
				});
			});
			window.addEventListener('offline', () => {
				this.$store.commit('SET_SNACKBAR', {
					type: 'error',
					title: '',
					content: 'Không có kết nối internet, vui lòng kiểm tra lại đường truyền.',
				});
			});
		}
		// this.$nextTick(() => {
		// 	try {
		// 		const target = document.querySelector('#fst-loading');
		// 		target?.classList.add('d-none');
		// 	} catch (error) {
		// 		console.log(error);
		// 	}
		// });
	},
	watch: {
		snackbarState: {
			deep: true,
			handler() {
				this.snackbarEnable = true;
			},
		},
		auth: {
			deep: true,
			handler() {
				if (this.auth && this.auth.isAuth) {
					this.$store.dispatch('signature/getCurrentSignature');
					this.$store.dispatch('categories/getListCategories');
					this.$store.dispatch('users/getDepartments');
					this.$store.dispatch('users/getCompanies');
					this.$store.dispatch('users/getUsers');
					this.$store.dispatch('users/getUserActive');
					this.$store.dispatch('others/getAllFields');
					this.$store.dispatch('getListNoti');
					if (this.refInterval) {
						clearInterval(this.refInterval);
					}
					this.refInterval = setInterval(() => {
						this.$store.dispatch('getListNoti');
					}, 12000);
				}
			},
		},
	},
	methods: {
		handlerClosePopup() {
			this.$store.commit('SET_LIST_IMAGE_PREVIEW', []);
			this.$store.commit('SET_INDEX_IMAGE_PREVIEW', 0);
		},
	},
});
