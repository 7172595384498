const notiRepo = (axios: any) => {
	return {
		updateRead(_id: number | string, _params: any = {}) {
			return axios
				.post(`/api/notifications/${_id}/read`, _params)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
		updateReceived(_params: any = {}) {
			return axios
				.post(`api/notifications/received`, _params)
				.then((res: any) => {
					return res;
				})
				.catch((err: any) => {
					return err.response;
				});
		},
	};
};
export default notiRepo;
