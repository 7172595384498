const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

import axios from 'axios';
const tasks = {
	namespaced: true,
	state: {
		detailTaskStatus: true,
		detailTaskPending: true,
		listTaskPending: false,
		listTask: [],
		listLogs: [],
		Task: [],
		filterTask: {},
		maxCountCurrentTask: 0,
		currentSizeTask: 15,
		previousOffsetTop: 0,
		currentTaskDetail: {},
		currentTaskComments: {},
		currentTaskCommentsTotal: 0,
		taskFirstLoading: [],
		sideBarListTask: {
			assign: [],
			follow: [],
			own: [],
		},
		taskStatistical: {},
	},
	getters: {},
	mutations: {
		SET_LIST_TASK_CACHE(state: Record<string, any>, { listTask, type }: Record<string, any>) {
			state.sideBarListTask[type] = listTask;
		},
		UPDATE_COMMENT_COUNTER(state: Record<string, any>, { task, type }: Record<string, any>) {
			for (const key in state.sideBarListTask) {
				state.sideBarListTask[key].forEach((t: Record<string, any>) => {
					if (t.id === task.id) {
						t.total_comments = type === 'increase' ? (t.total_comments += 1) : (t.total_comments -= 1);
					}
				});
			}
		},
		UPDATE_TASKDETAIL_COUNTER(state: Record<string, any>, { task }: Record<string, any>) {
			for (const key in state.sideBarListTask) {
				const idx = state.sideBarListTask[key].findIndex((t: Record<string, any>) => t.id === task.id);
				if (idx !== -1) {
					state.sideBarListTask[key].splice(idx, 1, task);
				}
			}
		},

		SET_LIST_TASK(state: Record<string, unknown>, tasks: any) {
			state.listTask = tasks;
		},
		SET_TASK_STATISTICAL(state: Record<string, unknown>, tasks: any) {
			state.taskStatistical = tasks;
		},
		SET_TASK_ACTIVITIES(state: Record<string, unknown>, logs: any) {
			state.listLogs = logs;
		},
		SET_CURRENT_TASK_DETAIL(state: Record<string, unknown>, task: Record<string, unknown>) {
			state.currentTaskDetail = task;
		},
		SET_DETAIL_TASK_DATA_STATUS(state: Record<string, unknown>, status: boolean) {
			state.detailTaskStatus = status;
		},
		SET_DETAIL_TASK_PENDING_STATUS(state: Record<string, unknown>, status: boolean) {
			state.detailTaskPending = status;
		},
		SET_LIST_TASK_PENDING(state: Record<string, unknown>, status: boolean) {
			state.listTaskPending = status;
		},
		SET_CURRENT_TASK_COMMENTS(state: Record<string, unknown>, comments: Record<string, unknown>[]) {
			state.currentTaskComments = comments;
		},
		SET_CURRENT_SIZE_TASK(state: Record<string, unknown>, numb: number) {
			state.currentSizeTask = numb;
		},
		SET_PREVIOUS_OFFSET_TOP(state: Record<string, unknown>, numb: number) {
			state.previousOffsetTop = numb;
		},
		SET_MAX_COUNT_CURRENT_TASK(state: Record<string, unknown>, count: number) {
			state.maxCountCurrentTask = count;
		},
		SET_CURRENT_TASK_COMMENTS_COUNTER(state: Record<string, unknown>, num: number) {
			state.currentTaskCommentsTotal = num;
		},
		SET_TASK_BY_ID(state: Record<string, unknown>, tasks: any) {
			state.listTask = tasks;
		},
		SET_FILTER_TASK(state: Record<string, unknown>, filters: any) {
			state.filterTask = filters;
		},
		PUSH_LOADED_TYPE(state: Record<string, any>, type: string) {
			if (!state.taskFirstLoading.includes(type)) {
				state.taskFirstLoading.push(type);
			}
		},
	},
	actions: {
		getTaskStatistical({ commit }: any) {
			axios
				.get(`${VUE_APP_BASE_URL}/api/staff/tasks/statistics`)
				.then(res => {
					commit('SET_TASK_STATISTICAL', res.data.data);
				})
				.catch(err => {
					console.log(err);
				});
		},
		getTaskById({ commit, state }: any, id: number | string) {
			if (state.currentTaskDetail && state.currentTaskDetail.id === id) {
				return;
			}
			commit('SET_DETAIL_TASK_PENDING_STATUS', true);
			axios
				.get(`${VUE_APP_BASE_URL}/api/staff/tasks/${id}`)
				.then(
					res => {
						commit('SET_DETAIL_TASK_PENDING_STATUS', false);
						commit('SET_DETAIL_TASK_DATA_STATUS', true);
						commit('SET_CURRENT_TASK_DETAIL', res.data.data.task);
					},
					err => {
						commit('SET_DETAIL_TASK_PENDING_STATUS', false);
						commit('SET_DETAIL_TASK_DATA_STATUS', false);
					}
				)
				.catch(err => {
					commit('SET_DETAIL_TASK_PENDING_STATUS', false);
					commit('SET_DETAIL_TASK_DATA_STATUS', false);
					console.log(err);
				});
		},
		syncTaskById({ commit, state }: any, id: number | string) {
			axios
				.get(`${VUE_APP_BASE_URL}/api/staff/tasks/${id}`)
				.then(
					res => {
						commit('UPDATE_TASKDETAIL_COUNTER', { task: res.data.data.task });
					},
					err => {
						commit('SET_DETAIL_TASK_PENDING_STATUS', false);
						commit('SET_DETAIL_TASK_DATA_STATUS', false);
					}
				)
				.catch(err => {
					commit('SET_DETAIL_TASK_PENDING_STATUS', false);
					commit('SET_DETAIL_TASK_DATA_STATUS', false);
					console.log(err);
				});
		},
		getTaskCommentsById({ commit, state }: any, id: number | string) {
			// if (state.currentTaskDetail && state.currentTaskDetail.id === +id) {
			// 	return;
			// }
			axios
				.get(`${VUE_APP_BASE_URL}/api/staff/tasks/${id}/comments?include=feedback`)
				.then(res => {
					const obj = {
						comments: res.data.data.task_comments,
						total: res.data.data.meta.pagination.total,
					};
					commit('SET_CURRENT_TASK_COMMENTS', obj);
					commit('SET_CURRENT_TASK_COMMENTS_COUNTER', res.data.data.meta.pagination.total);
				})
				.catch(err => {
					console.log(err);
				});
		},
		getTaskActivities({ commit, state }: any, { id, scrollAble }: Record<string, any>) {
			const body = {
				scrollAble: scrollAble,
			};
			axios
				.get(`${VUE_APP_BASE_URL}/api/staff/tasks/${id}/list-logs?include=causer`, {
					params: body,
				})
				.then(res => {
					commit('SET_TASK_ACTIVITIES', res.data.data.logs);
				})
				.catch(err => {
					console.log(err);
				});
		},
		loadMoreTaskCommentsById({ commit, state }: any, { id, per_page }: Record<string, any>) {
			axios
				.get(`${VUE_APP_BASE_URL}/api/staff/tasks/${id}/comments`, {
					params: {
						size: per_page,
					},
				})
				.then(res => {
					const obj = {
						comments: res.data.data.task_comments,
						total: res.data.data.meta.pagination.total,
					};
					commit('SET_CURRENT_TASK_COMMENTS', obj);
					commit('SET_CURRENT_TASK_COMMENTS_COUNTER', res.data.data.meta.pagination.total);
				})
				.catch(err => {
					console.log(err);
				});
		},
		getListTask({ commit, state }: any) {
			const body = state.filterTask;
			commit('SET_LIST_TASK_PENDING', true);

			axios
				.get(`${VUE_APP_BASE_URL}/api/staff/tasks`, {
					params: { ...body, size: state.currentSizeTask },
				})
				.then(
					res => {
						commit('SET_LIST_TASK_PENDING', false);
						if (body.type) {
							commit('SET_LIST_TASK_CACHE', { listTask: res.data.data.tasks, type: body.type });
							commit('PUSH_LOADED_TYPE', body.type);
						}
						commit('SET_LIST_TASK', res.data.data.tasks);
						commit('SET_MAX_COUNT_CURRENT_TASK', res.data.data.meta.pagination.total);
					},
					err => {
						commit('SET_LIST_TASK_PENDING', false);
						console.log(err.response);
					}
				)
				.catch(err => {
					commit('SET_LIST_TASK_PENDING', false);
					console.log(err);
				});
		},
		getListTaskById({ commit }: any, id: any) {
			axios
				.get(`${VUE_APP_BASE_URL}/api/staff/tasks/${id}`)
				.then(res => {
					commit('SET_TASK_BY_ID', res.data.data.tasks);
				})
				.catch(err => {
					console.log(err);
				});
		},
	},
};
export default tasks;
