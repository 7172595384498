






import { reactive, ref, defineComponent } from '@vue/composition-api';
import DefaultLayout from '@/layouts/default.vue';
import ContainerLogin from '@/components/containers/ContainerLogin.vue';
import { mapState } from 'vuex';
export default defineComponent({
	components: {
		DefaultLayout,
		ContainerLogin,
	},
	setup: () => {
		return {};
	},
	computed: {
		...mapState({
			auth: (state: any) => state.auth,
		}),
	},
	mounted() {
		if (this.auth.user && this.auth.user.id) {
			this.$router.push('/');
		}
		this.$nextTick(() => {
			try {
				const target = document.querySelector('#fst-loading');
				target?.classList.add('d-none');
			} catch (error) {
				console.log(error);
			}
		});
	},
	watch: {
		auth: {
			deep: true,
			handler() {
				if (this.auth.user && this.auth.user.id) {
					this.$router.push('/');
				}
			},
		},
	},
	methods: {},
});
