



























































import ThreeDots from '@/components/Loaders/ThreeDots.vue';
import { defineComponent, ref } from '@vue/composition-api';
import api from '@/services';
import { mapState } from 'vuex';
export default defineComponent({
	components: { ThreeDots },
	props: ['handleRedirectTodo', 'handleRedirectApproval', 'handleRedirectTicket'],
	setup: () => {
		let per_page = ref<number>(10);
		let totalListNotifi = ref<number>(0);
		return {
			per_page,
			totalListNotifi,
		};
	},
	data() {
		return {
			refNotiScroll: 0,
			CHAT_URL: process.env.VUE_APP_CHAT_URL,
		};
	},
	computed: {
		...mapState({
			listNoti: (state: any) => state.listNoti,
			currentSizeTask: (state: any) => state.tasks.currentSizeTask,
			currentNotiCounter: (state: any) => state.currentNotiCounter,
			preventLoadmoreNoti: (state: any) => state.preventLoadmoreNoti,
			totalNotiCounter: (state: any) => state.totalNotiCounter,
			onLoadingNoti: (state: any) => state.onLoadingNoti,
		}),
		listNotiComputed() {
			const arr = Array.isArray(this.listNoti) ? this.listNoti.slice() : [];
			return arr;
		},
	},
	created() {
		// this.getTotalNotifi();
		// this.getNotifi(this.per_page);
	},
	methods: {
		logScroll(e: any) {
			if (this.preventLoadmoreNoti || this.currentNotiCounter >= this.totalNotiCounter.total) {
				return;
			}
			if (this.refNotiScroll) {
				clearTimeout(this.refNotiScroll);
			}
			this.refNotiScroll = setTimeout(() => {
				if (e.target.clientHeight + e.target.scrollTop + 200 >= e.target.scrollHeight) {
					console.log('scroll triggered');

					this.$store.commit('SET_CURRENT_NOTI_COUNTER', this.currentNotiCounter + 10);
					this.$store.dispatch('getListNoti');
				}
			}, 300);
		},
		handleReceived() {
			api.noti.updateReceived();
		},
		handleRedirectDiscussion(_idTarget: number | string) {
			const url = `${this.CHAT_URL}/discussion/${_idTarget}?chatTab=discussion&per_page=15`;
			window.open(url);
		},
		handleRedirectNoti(_idTarget: number | string) {
			const url = `${this.CHAT_URL}/announcement/${_idTarget}`;
			window.open(url);
		},
		async handleClickOnNoti(_noti: Record<string, any>) {
			if (_noti.data && _noti.data.action) {
				if (_noti.data.action === 'detail') {
					// const notiEventArray = _noti.data.event.split('.')
					if (String(_noti.data.target).toLowerCase() === 'task') {
						this.handleRedirectTodo(+_noti.data.id);
					}
					if (String(_noti.data.target).toLowerCase() === 'approval') {
						this.handleRedirectApproval(+_noti.data.id, _noti);
					}
					if (String(_noti.data.target).toLowerCase() === 'ticket') {
						this.handleRedirectTicket(+_noti.data.id);
					}
					if (String(_noti.data.target).toLowerCase() === 'discussion') {
						this.handleRedirectDiscussion(+_noti.data.id);
					}
					if (String(_noti.data.target).toLowerCase() === 'announcement') {
						this.handleRedirectNoti(+_noti.data.id);
					}
				}
			}
			const res = await api.noti.updateRead(_noti.id);
			if (res.status && res.status >= 200 && res.status < 400) {
				this.$store.commit('UPDATE_READ_NOTI', _noti);
			}
			console.log(res);
		},
		// async getNotifi(_perpage:number) {
		//   const _data = await this.$api.notifications.getNotifi(_perpage);
		//   _data.data.meta.code >= 200 && _data.data.meta.code < 400
		//     ? (() => {
		//         this.notifications = _data.data.data;
		//         this.per_page = _data.data.meta.pagination.per_page;
		//         this.totalListNotifi = _data.data.meta.pagination.total;
		//       })()
		//     : (() => {
		//         this.color = 'error';
		//         this.message = 'Có lỗi xảy ra.Vui lòng thử lại sau';
		//         this.snackbar = true;
		//       })();
		// },
		// handleRead(notifi:any) {
		// notifi.read_at
		// ? (() => {})()
		// : (() => {
		//     this.postRead(notifi.id);
		//   })();
		// },
		// async postRead(_id:any) {
		//   const _data = await this.$api.notifications.postRead(_id);
		//   _data.data.meta.code >= 200 && _data.data.meta.code < 400
		//     ? (() => {
		//         this.notifications = this.notifications.map((item) => {
		//           return item.id === _id
		//             ? {
		//                 ...item,
		//                 read_at: new Date()
		//               }
		//             : item;
		//         });
		//       })()
		//     : (() => {
		//         this.color = 'error';
		//         this.message = 'Có lỗi xảy ra.Vui lòng thử lại sau';
		//         this.snackbar = true;
		//       })();
		// }
	},
});
