












































































































































































































































































































































































































// "
import { ref, defineComponent, watch, computed } from '@vue/composition-api';
import ShippingUnactive from '../../assets/images/sidebar-icon/shipping_u.svg';
import ShippingActive from '../../assets/images/sidebar-icon/shipping.svg';
import CategoryActive from '../../assets/images/sidebar-icon/danh-muc-sidebar-active.png';
import CategoryUnActive from '../../assets/images/sidebar-icon/danh-muc-sidebar-unactive.png';
import SignoutUnactive from '../../assets/images/sidebar-icon/signout_u.svg';
import SignoutActive from '../../assets/images/sidebar-icon/signout.svg';
import ChuKyActive from '../../assets/images/sidebar-icon/chu-ky-sidebar-active.png';
import ChuKyUnActive from '../../assets/images/sidebar-icon/chu-ky-sidebar-unactive.png';
import ProposeActive from '../../assets/images/sidebar-icon/dexuat-sidebar-active.png';
import ProposeUnactive from '../../assets/images/sidebar-icon/dexuat-sidebar-unactive.png';
import ApprovalActive from '../../assets/images/sidebar-icon/canduyet-sidebar-active.svg';
import theodoiActive from '../../assets/images/sidebar-icon/theodoi-sidebar-active.png';
import theodoiUnactive from '../../assets/images/sidebar-icon/theodoi-sidebar-unactive.png';
import ApprovalUnactive from '@/assets/images/sidebar-icon/canduyet-sidebar-unactive.svg';
import ConfirmSignout from '@/components/popup/ConfirmSignout.vue';
import DialogNotification from '@/components/Form/DialogNotification.vue';
import ConfirmCancelVietnamese from '@/components/popup/ConfirmCancelVietnamese.vue';
import useRouteQuery from '@/utils/uses/routerQuery/useRouteQuery';
import { IdentifyObject } from '@/InterfaceModel/CustomObject';
import DialogAddJob from '@/components/Form/DialogAddJob.vue';
import route from '@/router/index';
import api from '@/services';
import vClickOutside from 'v-click-outside';
import { tDate } from 'validation_t/src';
import { convertDateToStr } from '@/utils/dateHelper';
import io from 'socket.io-client';

import { mapGetters, mapState } from 'vuex';

export default defineComponent({
	name: 'SideBar',
	props: {
		sidebarIsCollapse: Boolean,
		toggleColappse: Function,
		Tab: String,
	},
	components: {
		ConfirmSignout,
		DialogNotification,
		DialogAddJob,
		ConfirmCancelVietnamese,
	},
	setup: ctx => {
		const { queryRoute, stringQueryRender, getQueryRoute } = useRouteQuery();
		let drawer = ref<boolean>(true);
		let mini = ref<boolean>(false);
		let isVisible = ref<boolean>(false);
		const loadingBtn = ref<boolean>(false);
		let showOptionState = ref<boolean>(false);
		let showOptionStatus = ref<boolean>(false);
		let statusTaskPending = ref<boolean>(false);
		let status = ref<number>(-1);
		let important = ref<number>(0);
		let maxSize = ref<number>(15);
		let errMsg = ref<string>('');
		let statusName = ref<string>('Tất cả');
		let currentRouteName = ref<string>('nthing-hp');
		const currentRouteQuery = ref<string>(stringQueryRender);
		let refInterval = ref<number>(0);
		let currentTab = ref<string>('aprroval');
		let filterBy = ref<string>('');
		let textQuery = ref<string>('');
		const isVisibleConfirm = ref<boolean>(false);
		const setIsVisibleConfirm = (payload: boolean) => {
			isVisibleConfirm.value = payload;
		};
		const setMini = (type: boolean): any => {
			mini.value = type;
		};
		const setDrawer = (type: boolean) => {
			drawer.value = type;
		};
		const setCurrentRouteName = (name: string): any => {
			currentRouteName.value = name;
		};
		const setCurrentRouteQuery = (payload: Record<string, unknown>): any => {
			let pairO = {
				...payload,
			};
			if (!pairO.search) {
				delete pairO.search;
			}
			if (!pairO.status_id) {
				delete pairO.status_id;
			}
			currentRouteQuery.value = getQueryRoute(pairO);
		};
		const setIsVisible = (value: boolean) => {
			isVisible.value = value;
		};
		const setLoadingBtn = (payload: boolean) => {
			loadingBtn.value = payload;
		};
		const setCurrentTab = (tab: string): any => {
			currentTab.value = tab;
		};
		const setShowOptionState = (type: boolean) => {
			showOptionState.value = type;
		};
		const setShowOptionStatus = (type: boolean) => {
			showOptionStatus.value = type;
		};
		const setStatusTaskPending = (type: boolean) => {
			statusTaskPending.value = type;
		};
		const setErrMsg = (payload: string) => {
			errMsg.value = payload;
		};

		watch(currentRouteQuery, currentValue => {
			route.push(`${currentValue}`);
		});
		watch(textQuery, search => {
			if (refInterval.value) {
				clearTimeout(refInterval.value);
			}

			refInterval.value = setTimeout(() => {
				setCurrentRouteQuery({
					...queryRoute,
					search,
				});
			}, 600);
		});
		watch(filterBy, type => {
			setCurrentRouteQuery({
				...queryRoute,
				type,
			});
		});
		watch(status, status_id => {
			setCurrentRouteQuery({
				...queryRoute,
				status_id,
			});
		});
		watch(important, important => {
			setCurrentRouteQuery({
				...queryRoute,
				important,
			});
		});

		return {
			drawer,
			mini,
			textQuery,
			status,
			errMsg,
			important,
			maxSize,
			loadingBtn,
			statusName,
			currentRouteQuery,
			showOptionState,
			showOptionStatus,
			statusTaskPending,
			setStatusTaskPending,
			currentRouteName,
			currentTab,
			isVisibleConfirm,
			setIsVisibleConfirm,
			isVisible,
			queryRoute,
			filterBy,
			setMini,
			setDrawer,
			setCurrentRouteQuery,
			setIsVisible,
			setErrMsg,
			setLoadingBtn,
			setShowOptionState,
			setShowOptionStatus,
			setCurrentRouteName,
			setCurrentTab,
		};
	},

	data() {
		return {
			CHAT_URL: process.env.VUE_APP_CHAT_URL,
			SOCKET_IO_URL: process.env.VUE_APP_SOCKET_IO_URL,
			signOut: false,
			currentId: 0,
			refScroll: 0,
			navField: [
				{
					icon: {
						unActive: ProposeUnactive,
						active: ProposeActive,
					},
					tab: 'aprroval',
					groupText: 'Tôi gửi đi',
					isGroup: false,
					matchToActive: ['de-xuat', 'index', 'de xuat tao moi', 'de xuat chinh sua'],
					action: () => {
						this.$router.push('/');
						this.$store.commit('CACHED_PAGINATION', {
							total: 1,
							total_pages: 1,
							size: 15,
							page: 1,
						});
					},
				},
				{
					icon: {
						unActive: ApprovalUnactive,
						active: ApprovalActive,
					},
					groupText: 'Gửi đến tôi',
					tab: 'aprroval',
					isGroup: false,
					matchToActive: ['cần duyệt', 'chi tiết cần duyệt'],
					action: () => {
						this.$router.push('/can-duyet');
						this.$store.commit('CACHED_PAGINATION', {
							total: 1,
							total_pages: 1,
							size: 15,
							page: 1,
						});
					},
				},

				{
					icon: {
						unActive: theodoiUnactive,
						active: theodoiActive,
					},
					groupText: 'Tôi theo dõi',
					tab: 'aprroval',
					isGroup: false,
					matchToActive: ['theo dõi', 'chi tiết theo dõi'],
					action: () => {
						this.$router.push('/theo-doi');
						this.$store.commit('CACHED_PAGINATION', {
							total: 1,
							total_pages: 1,
							size: 15,
							page: 1,
						});
					},
				},
				{
					tab: 'aprroval',
					icon: {
						unActive: CategoryUnActive,
						active: CategoryActive,
					},
					groupText: 'Danh mục',
					isGroup: false,
					matchToActive: ['danh-muc', 'danh-muc tao-moi'],
					action: () => {
						this.$router.push('/danh-muc');
						this.$store.commit('CACHED_PAGINATION', {
							total: 1,
							total_pages: 1,
							size: 15,
							page: 1,
						});
					},
				},
				//  {
				//   groupText: "User Guide",
				//   isGroup: false,
				//   matchToActive: ["about"],
				//   action: () => {
				//    this.$router.push("/about");
				//   },
				//  },
				{
					type: 'divider',
				},
				{
					groupText: 'Admin',
					isGroup: false,
					isBlock: true,
					matchToActive: [],
				},

				{
					icon: {
						unActive: ShippingUnactive,
						active: ShippingActive,
					},
					groupText: 'User',
					isGroup: false,
					matchToActive: ['user'],
					action: () => {
						this.$router.push('/user');
					},
				},

				{
					type: 'divider',
				},
				{
					icon: {
						unActive: SignoutUnactive,
						active: SignoutActive,
					},
					groupText: 'Sign Out',
					isGroup: false,
					matchToActive: ['none'],
					action: () => {
						this.$store.commit('CONFIRM_LOGOUT', true);
					},
				},
				//  {
				//   icon: {
				//    unActive: SignoutUnactive,
				//    active: SignoutActive,
				//   },
				//   tab: "aprroval",
				//   groupText: "Test Comma",
				//   isGroup: false,
				//   matchToActive: ["test-comma"],
				//   action: () => {
				//    this.$router.push("/test-comma");
				//   },
				//  },
				// {
				// 	icon: {
				// 		unActive: ChuKyUnActive,
				// 		active: ChuKyActive,
				// 	},
				// 	tab: 'aprroval',
				// 	groupText: 'Chữ ký',
				// 	isGroup: false,
				// 	matchToActive: ['chu-ky'],
				// 	action: () => {
				// 		this.$router.push('/chu-ky');
				// 	},
				// },
				//  {
				//   groupText: "Action",
				//   isGroup: true,
				//   matchToActive: ["table", "report"],
				//   listChild: [
				//    {
				//     itemText: "Report",
				//     matchToActive: ["report"],
				//     action: () => {
				//      this.$router.push("/report");
				//     },
				//    },
				//    {
				//     itemText: "Table",
				//     matchToActive: ["table"],
				//     action: () => {
				//      this.$router.push("/table");
				//     },
				//    },
				//   ],
				//  },
			],
		};
	},
	created() {
		this.setMini(this.isMini);
	},
	computed: {
		...mapState({
			logoutIsOpen: (state: any): boolean => state.logoutIsOpen,
			isMini: (state: any): boolean => state.isMini,
			statistical: (state: any): boolean => state.statistical,
			listTask: (state: any) => state.tasks.listTask,
			taskStatistical: (state: any): boolean => state.tasks.taskStatistical,
			sideBarListTask: (state: any) => state.tasks.sideBarListTask,
			maxCountCurrentTask: (state: any): number => state.tasks.maxCountCurrentTask,
			currentSizeTask: (state: any): number => state.tasks.currentSizeTask,
			previousOffsetTop: (state: any): number => state.tasks.previousOffsetTop,
			filterTask: (state: any): Record<string, any> => state.tasks.filterTask,
			listTaskPending: (state: any): Record<string, any> => state.tasks.listTaskPending,
			authToken: (state: any): Record<string, any> => state.auth.token,
			authUserObj: (state: any): Record<string, any> => state.auth.user,
			taskFirstLoading: (state: any): Record<string, any> => state.tasks.taskFirstLoading,
		}),
		...mapGetters({
			user: 'getUser',
		}),
		navFieldComputed() {
			const arr: Array<any> = this.navField.map((o: any) => o);
			const result: Array<any> = arr.filter((o: any) => o.tab === this.currentTab);
			return result;
		},
		listTaskComputed() {
			const listTaskCache = this.sideBarListTask[this.filterBy];
			const arr: Array<any> = listTaskCache;
			// const arr: Array<any> = this.listTask;
			return arr
				? arr.map((o: any) => {
						return {
							...o,
							status_vietnamese:
								o.status && o.status.id === 1
									? 'Mới'
									: o.status && o.status.id === 2
									? 'Đang làm'
									: o.status && o.status.id === 3
									? 'Hoàn thành'
									: o.status && o.status.id === 4
									? 'Hủy'
									: '',
						};
				  })
				: [];
		},
	},
	mounted() {
		console.log(this.user);
		const currentName = this.$route.name;
		if (currentName) {
			this.setCurrentRouteName(String(currentName).toLowerCase());
		}
		if (currentName && currentName === 'Giao việc') {
			this.currentTab = 'to-do';
		}
		if (currentName && currentName === 'Chi tiết giao việc') {
			this.currentTab = 'to-do';
		}
		if (this.queryRoute) {
			if (this.queryRoute.search) {
				this.textQuery = this.queryRoute.search;
			}
			let _obj: any = { ...this.queryRoute };
			delete _obj.search;
			// if (this.queryRoute.status_id === "0") {
			//   this.status = 0;
			// delete this.queryRoute.status_id;
			// if (!this.queryRoute.status_id) {
			//   this.status = -1;
			// }
			// }
			// this.setCurrenttypele(_obj)

			// this.setCurrentRouteQuery(this.queryRoute)
			Object.keys(this.queryRoute).forEach(k => this.queryRoute[k] == null && delete this.queryRoute[k]);
		}

		const isSameQuery =
			this.filterTask.type === this.queryRoute.type &&
			this.filterTask.status_id == this.queryRoute.status_id &&
			this.filterTask.important == this.queryRoute.important &&
			this.filterTask.search === this.queryRoute.search;
		if (this.currentTab === 'to-do') {
			const currentQuery = this.$route.query;
			this.maxSize = +currentQuery.size || 15;
			this.$nextTick(() => {
				this.scrollToSelected();
			});
		}
		if (this.currentTab === 'to-do' && !isSameQuery) {
			this.$store.dispatch('tasks/getTaskStatistical');
			const currentQuery = this.$route.query;
			if (+currentQuery.size) {
				// currentSizeTask
				if (+currentQuery.size !== this.currentSizeTask) {
					this.$store.commit('tasks/SET_CURRENT_SIZE_TASK', +currentQuery.size);
				}
			} else {
				this.$store.commit('tasks/SET_CURRENT_SIZE_TASK', +currentQuery.size || 15);
			}
			this.$store.commit('tasks/SET_FILTER_TASK', this.queryRoute);
			this.$store.dispatch('tasks/getListTask');
		}
		if (!this.$route.query.type && this.currentTab === 'to-do') {
			const id = this.$route.params.id;
			if (id) {
				this.$router.push(`/giao-viec/${id}?type=own&size=${this.currentSizeTask}`);
			} else {
				this.$router.push(`/giao-viec?type=own&size=${this.currentSizeTask}`);
			}
		}
		const token = this.authToken;
		const opt = {
			transports: ['websocket'],
			reconnect: true,
			name: 'main',
			path: '/socket.io',
			port: '443',
			query: {
				token: token,
			},
		};
		if (this.authUserObj) {
			const userId = this.authUserObj.user.id;
			const socket = io(this.SOCKET_IO_URL, opt);
			const channel = socket.emit('subscribe', {
				channel: `private-users-${userId}`,
				token: token,
				auth: {
					headers: {
						Authorization: token,
					},
				},
			});
			channel.on('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', (cb: any, msg: any) => {
				this.$store.dispatch('getListNoti');
			});
		}

		// console.log(socket);
		// this.$socket.$subscribe(`private-customer-${19}`, function (payload: any) {
		// 	console.log(payload);
		// });
		// console.log(this.$socket);
	},
	watch: {
		$route(_newVal) {
			const currentName = _newVal.name;
			if (currentName) {
				this.setCurrentRouteName(String(currentName).toLowerCase());
			}
			if (currentName && currentName === 'to-do') {
				this.currentTab = 'to-do';
			}
		},
		isMini(newVal) {
			this.setMini(newVal);
		},
		listTask: {
			deep: true,
			handler() {
				this.$nextTick(() => {
					this.scrollToSelected();
				});
			},
		},
		currentTab() {
			if (this.$router.app.$route.params) {
				this.currentId = Number(this.$router.app.$route.params.id);
			}
			if (this.$router.app.$route.query.type) {
				this.filterBy = String(this.$router.app.$route.query.type);
			}
			// if (
			//   this.$router.app.$route.query.status_id &&
			//   this.$router.app.$route.query.status_id === "0"
			// ) {
			//   this.statusName = "Tất cả";
			//   delete this.queryRoute.status_id;
			//   delete this.$router.app.$route.query.status_id;
			// }
			if (this.$router.app.$route.query.status_id && this.$router.app.$route.query.status_id === '1') {
				this.statusName = 'Mới';
			}
			if (this.$router.app.$route.query.status_id && this.$router.app.$route.query.status_id === '2') {
				this.statusName = 'Đang làm';
			}
			if (this.$router.app.$route.query.status_id && this.$router.app.$route.query.status_id === '3') {
				this.statusName = 'Hoàn thành';
			}
			if (this.$router.app.$route.query.status_id && this.$router.app.$route.query.status_id === '4') {
				this.statusName = 'Hủy';
			}
			if (this.$router.app.$route.query.important && this.$router.app.$route.query.important === '1') {
				this.statusName = 'Quan trọng';
			}
			if (this.currentTab === 'sso') {
				this.$nextTick(() => {
					this.currentTab = 'aprroval';
				});
			}
		},
	},
	methods: {
		async handleTakeComments(id: any) {
			const res = await api.task.comment(id, { pagination: false });
			if (!res) {
				this.$store.commit('SET_SNACKBAR', {
					type: 'error',
					title: '',
					content: 'Không lấy được thông tin bình luận. Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
				});
				return;
			}
			try {
				if (res.status > 199 && res.status < 399) {
					return [];
				} else {
					// messageErr.value =  res.data.message || res.data.data.error ;
					this.$store.commit('SET_SNACKBAR', {
						type: 'error',
						title: '',
						content: res.data.message || res.data.data.error,
					});
				}
			} catch (error) {
				//  messageErr.value = error;
				this.$store.commit('SET_SNACKBAR', {
					type: 'error',
					title: '',
					content: 'Không lấy được thông tin bình luận. Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
				});
			}
		},
		handleOpenSelectState() {
			this.setShowOptionState(true);
		},
		handleCloseSelectState() {
			this.setShowOptionState(false);
		},
		handleOpenSelectStatus() {
			this.setShowOptionStatus(true);
		},
		handleCloseStatusOnly() {
			this.setShowOptionStatus(false);
		},
		handleCloseSelectStatus(id: any) {
			if (id === 5) {
				this.important = 1;
				delete this.queryRoute.status_id;
			} else if (id === 0) {
				this.status = id;
				delete this.queryRoute.important;
				// delete this.$router.app.$route.query.status_id;
			} else {
				this.status = id;
				delete this.queryRoute.important;
			}
			Object.keys(this.queryRoute).forEach(k => this.queryRoute[k] == null && delete this.queryRoute[k]);
			this.setShowOptionStatus(false);
		},
		formatDate(ds: any) {
			let ar1 = ds.split('-');
			return `${ar1[2]}-${ar1[1]}-${ar1[0]}`;
		},
		formatTick(ds: boolean) {
			if (ds) {
				return '1';
			} else {
				return '0';
			}
		},
		async handlerDialogSubmit(value: any, value2: any, value3: any, value4: any) {
			// let due_date = this.formatDate(value.due_date);
			let due_date = this.formatDate(value.due_date) + ' ' + value.due_dateTime + ':00';
			let important = this.formatTick(value.is_important);
			let arrayId = value2.map((o: Record<string, any>) => o.id);
			let arrayFollowersId = value4.map((o: any) => o.id);
			let arrayFile = value3.map((o: any) => o);
			delete value.due_date;
			delete value.is_important;
			const formData = new FormData();
			arrayFile.forEach((element: any) => {
				formData.append('attachments[]', element.blob);
			});
			arrayFollowersId.forEach((element: any) => {
				formData.append('user_follow_ids[]', element);
			});
			arrayId.forEach((element: any) => {
				formData.append('user_do_ids[]', element);
			});
			formData.append('due_date', due_date);
			formData.append('important', important);
			formData.append('title', value.title);
			formData.append('content', value.content);
			this.setErrMsg('');
			this.setLoadingBtn(true);
			const res = await api.todo.create(formData);
			this.setLoadingBtn(false);
			if (!res) {
				return;
			}
			try {
				if (res.status > 199 && res.status < 399) {
					this.$store.commit('SET_SNACKBAR', {
						type: 'success',
						title: '',
						content: 'Tạo việc thành công',
					});
					this.$store.dispatch('tasks/getListTask');
					this.handleConfirmCancel();
				} else {
					this.setErrMsg(res.data.message || res.data.data.error);
					this.$store.commit('SET_SNACKBAR', {
						type: 'error',
						title: '',
						content: res.data.message || res.data.data.error,
					});
				}
			} catch (error) {
				console.log(error);
				this.$store.commit('SET_SNACKBAR', {
					type: 'error',
					title: '',
					content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
				});
			}
		},
		handlerDialogCancel() {
			this.setIsVisibleConfirm(true);
		},
		openDialogAddJob() {
			this.setIsVisible(true);
		},
		redirectToApps() {
			const prevTab = this.currentTab;
			window.open(`${process.env.VUE_APP_APPS_URL}`);
			this.$nextTick(() => {
				setTimeout(() => {
					this.currentTab = prevTab;
				}, 300);
			});
		},
		handleRedirectTodo(_idTask: number) {
			this.currentTab = 'to-do';
			this.handleDetailTodo(_idTask, { type: 'own' });
		},
		handleRedirectApproval(_idTask: number, noti: Record<string, any>) {
			this.currentTab = 'approval';
			switch (noti.data.type) {
				case 'creator':
					this.$router.push({
						path: `/de-xuat/chi-tiet/${_idTask}`,
						query: {},
					});
					break;
				case 'approver':
					this.$router.push({ path: `/can-duyet/${_idTask}`, query: {} });
					break;
				case 'follower':
					this.$router.push({
						path: `/theo-doi/chi-tiet/${_idTask}`,
						query: {},
					});
					break;
				default:
					this.$router.push({
						path: `/de-xuat/chi-tiet/${_idTask}`,
						query: {},
					});
					break;
			}
		},
		handleRedirectTicket(_idTicket: number) {
			window.open(`${this.CHAT_URL}/question`);
		},
		handleDetailTodo(id: number, partQuery: Record<string, any> = {}) {
			if (this.$router.app.$route.path.includes('/giao-viec/${id}')) {
				return;
			}
			let currentQuery = this.$route.query;
			if (Object.keys(partQuery).length) {
				currentQuery = {
					...currentQuery,
					...partQuery,
				};
			}
			this.$router.push({ path: `/giao-viec/${id}`, query: currentQuery });
			this.currentTab = 'to-do';
		},
		redirectToDo() {
			if (this.$router.app.$route.path.includes('/giao-viec')) {
				return;
			}
			this.$router.push(`/giao-viec?type=own&size=${this.currentSizeTask}`);
			this.currentTab = 'to-do';
		},
		redirectApproval() {
			if (
				this.$router.app.$route.path === '/' ||
				this.$router.app.$route.path === '/can-duyet' ||
				this.$router.app.$route.path === '/theo-doi' ||
				this.$router.app.$route.path === '/danh-muc' ||
				this.$router.app.$route.path === '/chu-ky'
			) {
				return;
			}
			this.$router.push('/');
			this.currentTab = 'approval';
		},
		toggleMini() {
			this.setMini(!this.mini);
		},
		onChangeTabs(val: any) {
			console.log(val);
		},
		handleLogout() {
			this.$store.commit('CONFIRM_LOGOUT', true);
		},
		scrollToSelected() {
			const target = document.querySelector('.task-block.selected-father');
			const wr = document.querySelector('#task-wrapper');
			if (target instanceof HTMLElement && wr instanceof HTMLElement) {
				wr.scrollTop = target.offsetTop - wr.offsetTop - 20;
				return;
			}
			if (wr instanceof HTMLElement) {
				wr.scrollTop = this.previousOffsetTop;
				return;
			}
		},
		taskScroll(e: any) {
			if (this.refScroll) {
				clearTimeout(this.refScroll);
			}
			if (this.listTaskPending) {
				return;
			}
			this.refScroll = setTimeout(() => {
				const target = e.target;
				if (target.scrollHeight - target.scrollTop > 850) {
					return;
				}

				const currentSize = this.currentSizeTask;
				if (currentSize < this.maxCountCurrentTask) {
					this.$store.commit('tasks/SET_PREVIOUS_OFFSET_TOP', target.scrollTop);
					this.$store.commit('tasks/SET_CURRENT_SIZE_TASK', +currentSize + 5);
					const currentQuery = this.$route.query;

					this.$router.push(
						{
							query: Object.assign({ ...currentQuery }, { size: currentSize + 5 }),
						},
						// eslint-disable-next-line @typescript-eslint/no-empty-function
						() => {}
					);
					this.$nextTick(() => {
						this.$store.dispatch('tasks/getListTask');
					});
				}
			}, 200);
		},
		handlerDialogConfirmCancel() {
			this.setIsVisibleConfirm(false);
		},
		handleConfirmCancel() {
			this.setIsVisible(false);
			this.setIsVisibleConfirm(false);
		},
		getDelay(date: any, second: any, first: any) {
			let newDate = new Date();
			let today = tDate.formatDateCustomize(newDate);
			let limit = tDate.formatDateCustomize(date);
			second = new Date(today.yyyy, today.MM, today.dd - 1);
			first = new Date(limit.yyyy, limit.MM, limit.dd - 1);
			let limitText = `${limit.hh}:${limit.mm} ${limit.dd}-${limit.MM}-${limit.yyyy}`;
			return limitText;
		},
		datediff(date: any, first: any, second: any) {
			let newDate = new Date();
			let today = tDate.formatDateCustomize(newDate);
			let limit = tDate.formatDateCustomize(date);
			second = new Date(today.yyyy, today.MM, today.dd - 1);
			if (limit) {
				first = new Date(limit.yyyy, limit.MM, limit.dd - 1);
			}
			let expiredDays = second - first;
			let diff = Math.round(expiredDays / (1000 * 60 * 60 * 24));
			return diff;
		},
	},
});
