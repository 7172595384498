const fileType = {
 state: {
  image: [
   "jpg",
   "png",
   "jpeg",
   "gif",
   "webp",
   "JPG",
   "PNG",
   "JPEG",
   "GIF",
   "WEBP",
  ],
  files: [
   "xlsx",
   "pdf",
   "docx",
   "txt",
   "text",
   "xls",
   "XLSX",
   "PDF",
   "DOCX",
   "TXT",
   "TEXT",
   "bpmn",
   "BPMN",
   "rar",
   "RAR",
   "zip",
   "ZIP",
   "apk",
   "APK",
   "exe",
   "EXE",
   "dll",
   "DLL",
   "json",
   "JSON",
  ],
  pdf: ["pdf", "PDF"],
  doc: ["docx", "DOCX", "doc"],
  xls: ["xls", "xlsx", "XLSX"],
  video: [
   "mp4",
   "MP4",
   "mpeg4",
   "MPEG4",
   "avi",
   "AVI",
   "mpg",
   "mpeg",
   "MPEG",
   "wmv",
   "WMV",
   "ogg",
   "Ogg",
   "OGG",
   "webm",
   "WebM",
   "WEBM",
   "mov",
   "MOV",
  ],
 },
};
export default fileType;
