









































































































































































































































































































import { defineComponent, onMounted, ref, watch, computed } from '@vue/composition-api';
import UploadFileApproval from '../Layout/page-view/approval/UploadFileApproval.vue';
import Avatar from '@/components/Layout/Avatar.vue';
import DialogAddNewMember from '../Layout/page-view/approval/DialogAddNewMember.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import defaultAvatar from '@/assets/images/default-avatar2.svg';
import { fileClipboardObj } from '@/InterfaceModel/Files';
import api from '@/services';
import router from '@/router';
import vClickOutside from 'v-click-outside';
import { tDate } from 'validation_t/src';
import { convertDateToStr } from '@/utils/dateHelper';

const now = new Date();
const _date = now.getDate().toString().padStart(2, '0');
const _month = (now.getMonth() + 1).toString().padStart(2, '0');
const _year = now.getFullYear();

const currentDateStr = `${_date}-${_month}-${_year}`;
export default defineComponent({
	components: {
		DatePicker,
		Avatar,
		UploadFileApproval,
		DialogAddNewMember,
	},
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
		errMsg: {
			type: String,
			default: '',
		},
		loadingBtn: {
			type: Boolean,
			default: false,
		},
		handlerCancel: {
			type: Function,
		},
		handlerSubmit: {
			type: Function,
		},
		selectedData: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	setup: (props, ctx) => {
		let formData = ref<Record<string, any>>({
			due_dateTime: '17:00',
		});
		let errors = ref<Record<string, any>>({});
		let formData2 = ref<fileClipboardObj[]>([]);
		let formData3 = ref<fileClipboardObj[]>([]);
		let participants = ref<Record<string, any>[]>([]);
		let followers = ref<Record<string, any>[]>([]);
		let companies = ref<Record<string, any>[]>([]);
		let memberUsernames = ref<Record<string, any>[]>([]);
		let guest = ref<any>('');
		let title = ref<string>('');
		let errortitle = ref<string>('');
		let content = ref<string>('');
		let errorDes = ref<string>('');
		let errorPar = ref<string>('');
		let errorDay = ref<string>('');
		let errMsg2 = props.errMsg;
		let is_important = ref<number>(0);
		let loadingSubmit = ref<boolean>(false);
		let isPopupOpened = ref<boolean>(false);
		let isEdit = ref<boolean>(false);
		let isVisibleMember = ref<boolean>(false);
		let isVisibleFollower = ref<boolean>(false);
		let guestList = ref<Record<string, any>[]>([]);
		const changeFileUpload = (payload: fileClipboardObj[]) => {
			formData2.value = payload;
			formData3.value = payload;
		};
		const btnCancelClick = () => {
			ctx.emit('handlerCancel');
		};
		const setIsPopupOpened = (payload: boolean) => {
			isPopupOpened.value = payload;
		};
		const setIsVisibleMember = (payload: boolean) => {
			isVisibleMember.value = payload;
		};
		const setIsVisibleFollower = (payload: boolean) => {
			isVisibleFollower.value = payload;
		};
		const isActive = ref<boolean>(false);
		const btnSubmitClick = () => {
			if (
				!formData.value.title ||
				!formData.value.content ||
				!participants.value.length ||
				!formData.value.due_date
			) {
				isActive.value = true;
				errortitle.value = 'Không được để trống tiêu đề';
				errorDes.value = 'Không được để trống nội dung';
				errorPar.value = 'Thêm người';
				errorDay.value = 'Không được để trống ngày hạn';
				return;
			}
			// const obj = { ...formData.value };
			// obj.due_date = obj.due_date + ' ' + obj.due_dateTime;
			// delete obj.due_dateTime;
			ctx.emit('handlerSubmit', formData.value, participants.value, formDataComputed3.value, followers.value);
		};
		let formDataComputed2 = computed(() => {
			const arr: Record<string, any>[] = formData2.value;
			return arr.map((o: any) => {
				return {
					...o,
					name: o.original_name || o.name,
				};
			});
		});
		let formDataComputed3 = computed(() => {
			const arr: Record<string, any>[] = formData3.value;
			return arr.map((o: any) => {
				return {
					...o,
					name: o.original_name || o.name,
				};
			});
		});
		return {
			errors,
			title,
			isEdit,
			formData,
			formData2,
			formDataComputed2,
			formData3,
			formDataComputed3,
			errortitle,
			isPopupOpened,
			setIsPopupOpened,
			content,
			errMsg2,
			isActive,
			errorDes,
			errorPar,
			errorDay,
			participants,
			followers,
			companies,
			memberUsernames,
			guestList,
			guest,
			is_important,
			loadingSubmit,
			isVisibleMember,
			isVisibleFollower,
			btnCancelClick,
			setIsVisibleMember,
			setIsVisibleFollower,
			btnSubmitClick,
			changeFileUpload,
		};
	},
	data: function () {
		let _date = currentDateStr;
		return {
			date: _date,
			loading: false,
			filterByName: '',
			users: [],
			departmentId: null,
			usersSelected: {},
			showUsers: 10,
			departments: [],
			newDepartments: [],
			checkUser: false,
			companyId: 'default',
			X_API_KEY: process.env.X_API_KEY,
			BASE_URL: process.env.VUE_APP_BASE_URL,
			modalShow: true,
			isContact: false,
			isLately: true,
			listMember: [],
			selectedContactUsername: [],
			count: 0,
			listMemberSelected: {},
			CHAT_URL: process.env.CHAT_URL,
			searchByName: '',
			filtersRecentContact: null,
			filtersContact: null,
			isLoadingUsers: false,
		};
	},
	watch: {
		selectedData: {
			deep: true,
			handler() {
				if (this.selectedData.id) {
					const o = { ...this.selectedData };
					if (o.due_date) {
						const dd = o.due_date;
						o.due_dateTime = dd.split(' ')[1].slice(0, 5);
					}
					this.formData = o;
					this.formData.is_important = !!o.important;
					this.participants = o.users_do;
					this.followers = o.users_follow;
					this.isEdit = true;
					if (o.due_date) {
						const dd = o.due_date;
						this.formData.due_date = this.formatDateTimeout(dd);
						// this.formData.due_dateTime = dd.split(' ')[1].slice(0, 5);
						// this.formData.due_dateTime = '17:00';
					}
					if (o.attachments) {
						this.formData2 = o.attachments;
						this.formData3 = this.formData2.slice();
					}
				}
			},
		},
		isVisible() {
			if (!this.isVisible) {
				this.errortitle = '';
				this.errorDes = '';
				this.errorPar = '';
				this.errorDay = '';
				this.formData = {
					due_dateTime: '17:00',
				};
				this.participants = [];
				this.isActive = false;
				this.followers = [];
				this.formData2 = [];
				this.formData3 = [];
			}
		},
	},
	computed: {},
	methods: {
		openPopup() {
			this.setIsPopupOpened(!this.isPopupOpened);
		},
		closePopup() {
			this.setIsPopupOpened(false);
		},
		addNewMembersToMetting: function (members: any) {
			this.participants = members;
		},
		addNewFollowersToMetting: function (members: any) {
			this.followers = members;
		},
		handleChangeParticipants(_participants: any) {
			this.participants = _participants;
		},
		handleChangeFollowers(_followers: any) {
			this.followers = _followers;
		},
		getEmail() {
			this.guestList.push(this.guest);
			this.guest = '';
		},
		removeGuest(idx: any) {
			this.guestList.splice(idx, 1);
		},
		formatDateTimeout(_date: any) {
			let date = tDate.formatDateCustomize(convertDateToStr(_date));
			return `${date.dd}-${date.MM}-${date.yyyy}`;
		},
		removeFile(idx: any) {
			this.formData3.splice(idx, 1);
		},
		returnFileName2(_name: any) {
			let stringName = String(_name);
			let prevDotName = stringName.slice(0, stringName.lastIndexOf('.'));
			let txt = '';
			if (prevDotName.length > 15) {
				txt = prevDotName.slice(0, 12) + '...';
			} else {
				txt = prevDotName + '.';
			}
			return `${txt}${stringName.split('.').pop()}`;
		},
		notBeforeToday(date: any) {
			return date < new Date(new Date().setHours(0, 0, 0, 0));
		},
		openAddMember() {
			this.setIsVisibleMember(true);
		},
		openAddFollower() {
			this.setIsVisibleFollower(true);
		},
		handlerDialogCancel() {
			this.setIsVisibleMember(false);
		},
		handlerDialogCancelFollower() {
			this.setIsVisibleFollower(false);
		},
		returnFileName(_name: any) {
			let stringName = String(_name);
			return `${stringName}`;
		},
		replaceByDefault(e: any) {
			e.target.src = defaultAvatar;
		},
		handleRemoveUserSelected(userId: any) {
			let _usersSelected = this.participants.filter(o => o.id !== userId);
			this.participants = _usersSelected;
		},
		handleRemoveFollowerSelected(userId: any) {
			let _usersSelected = this.followers.filter(o => o.id !== userId);
			this.followers = _usersSelected;
		},
	},
});
