

















import { reactive, ref, defineComponent } from '@vue/composition-api';
import { mapState } from 'vuex';
import store from '@/store';
export default defineComponent({
	setup: () => {
		return {};
	},
	watch: {},
	computed: {
		...mapState({
			logoutIsOpen: (state: any): boolean => state.logoutIsOpen,
		}),
	},
	methods: {
		signOut() {
			localStorage.removeItem('auth._token.local');
			store.commit('SET_LOGOUT_USER');
			this.$store.commit('CONFIRM_LOGOUT', false);

			document.cookie = 'token=removed';
			document.cookie = 'token=removed; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.asgl.net.vn';
			document.cookie = 'token=removed; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/login; domain=.asgl.net.vn';
			// let currentUrl = window.location.origin;
			this.$router.push('/login');
			window.close();
		},
		cancel() {
			this.$store.commit('CONFIRM_LOGOUT', false);
		},
	},
});
