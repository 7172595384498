














import store from "@/store/index";
import route from "@/router/index";
import { defineComponent, ref } from "@vue/composition-api";
import api from "@/services";
import Axios from "axios";
export default defineComponent({
 components: {},
 setup: () => {
  let username = ref<string>("");
  let password = ref<string>("");
  let isMessage = ref<string>("");
  let loadingLogin = ref<boolean>(false);
  let errorMsg = ref<string>("");
  let clickedSubmit = ref<boolean>(false);
  let rememberMe = ref<boolean>(true);

  const setLoadingLogin = (payload: boolean) => {
   loadingLogin.value = payload;
  };
  const setRememberMe = (payload: boolean) => {
   rememberMe.value = payload;
  };
  const setErrorMsg = (payload: string) => {
   errorMsg.value = payload;
  };
  const setClickedSubmit = (payload: boolean) => {
   clickedSubmit.value = payload;
  };
  const setIsMessage = (payload: string) => {
   isMessage.value = payload;
  };
  const setUsername = (payload: string) => {
   username.value = payload;
  };
  const setPassword = (payload: string) => {
   password.value = payload;
  };
  try {
   const previousLogger = localStorage.getItem("prev_account");
   if (previousLogger) {
    const logger = JSON.parse(previousLogger);
    setUsername(logger.login);
    setPassword(logger.password);
   }
  } catch (error) {
   console.log(error);
  }
  const authenticate = async (query: Record<string, string>) => {
   const res = await api.user.loginUser(query);
   if (!res) {
    setLoadingLogin(false);
    setIsMessage("Có lỗi không thể đăng nhập , vui lòng thử lại sau");
    return;
   }
   if (res.status > 399) {
    setLoadingLogin(false);
    setIsMessage(res.data.message || res.data.meta.message);
    return;
   }
   try {
    if (rememberMe.value) {
     localStorage.setItem("prev_account", JSON.stringify(query));
    } else {
     localStorage.removeItem("prev_account");
    }
    localStorage.setItem("auth._token.local", res.data.data.token);
    Axios.defaults.headers = {
     Authorization: `Bearer ${res.data.data.token}`,
    };
    const current_token = res.data.data.token;
    const resU = await api.user.getUserInfo();
    setLoadingLogin(false);
    route.push("/");
    if (!resU) {
     localStorage.removeItem("auth._token.local");
     setErrorMsg("");
     return;
    }
    try {
     if (resU.status > 399) {
      localStorage.removeItem("auth._token.local");
      setErrorMsg("");
      return;
     }
     if (resU.response && !resU.response.data.success) {
      localStorage.removeItem("auth._token.local");
      setErrorMsg("");
      return;
     }

     const auth_set = {
      isAuth: true,
      user: resU.data.data,
      token: `Bearer ${current_token}`,
     };
     store.commit("SET_USER_LOGGEDIN", auth_set);
    } catch (error) {
     console.log(error);
     setErrorMsg("");
    }
   } catch (error) {
    setLoadingLogin(false);
    console.log(error);
   }
  };
  return {
   username,
   password,
   clickedSubmit,
   loadingLogin,
   errorMsg,
   isMessage,
   rememberMe,
   setRememberMe,
   setLoadingLogin,
   setErrorMsg,
   authenticate,
   setClickedSubmit,
   setIsMessage,
  };
 },
 data() {
  return {
   rules: {
    required: (value: any) => !!value || "Không được để trống trường này.",
   },
  };
 },
 methods: {
  async submit() {
   this.setClickedSubmit(true);
   this.setErrorMsg("");
   this.setLoadingLogin(true);
   if (!this.username || !this.password) {
    this.setLoadingLogin(false);
    return;
   }
   let body = {
    login: this.username,
    password: this.password,
   };
   this.authenticate({ ...body });
  },
  handleFocusPass() {
   console.log(this);
   if (this.$refs.passwordCl) {
    const inputPassword = this.$refs.passwordCl as HTMLElement;

    if (inputPassword) {
     inputPassword.focus();
    }
   }
  },
 },
});
