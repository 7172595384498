


















import Vue from "vue";
import { mapState } from "vuex";
import TextFilter from "@/components/Shared/TextFilter.vue";
export default Vue.extend({
 components: { TextFilter },
 props: {},
 data() {
  return {
   items: [
    {
     text: "Sign Out",
     action: () => {
      this.$store.commit("CONFIRM_LOGOUT", true);
     },
    },
   ],
  };
 },
 computed: {
  ...mapState({
   user: (state: any) => state.auth.user.user,
   logoutIsOpen: (state: any): boolean => state.logoutIsOpen,
   isMini: (state: any): boolean => state.isMini,
   currentBreadcrumb: (state: any): any => state.currentBreadcrumb,
   breadCrumb: (state: any): any => state.breadCrumb,
   showFilter: (state: any): any => state.showFilter,
   addLib: (state: any): any => state.addLib,
   placeHolder: (state: any): any => state.placeHolder,
   addLibAction: (state: any): any => state.addLibAction,
  }),
 },
 mounted() {
  //  this.$store.commit("SET_ADDLIBACTION", this.showDialog);
 },
 methods: {
  toggleMini() {
   this.$store.commit("SET_MINI", !this.isMini);
  },
  showDialog() {
   this.$store.commit("SET_VISIBLE_DIALOG", true);
  },
 },
});
